import React from "react";
import { Card } from '../components/Card';
import { ReturnAskTheExpertsLogo } from './AskTheExpertsLogo'



function AskTheExperts({data}) {   

let allEvents =  data && data[0].eventDaySessions.filter(function (e) {
  return e.eventSession.sessionType.code == "ASK";
});


allEvents && allEvents.sort((a, b) => (a.eventSession.eventSessionName > b.eventSession.eventSessionName) ? 1 : -1)

  const Item = ({ data, index }) => { 
    let sessionName = data && data.eventSession && data.eventSession.eventSessionName


    sessionName = sessionName.split("Health Plan - ").pop()
 


   
    let CardView = false

    if(CardView){

      
      return  (
      <Card title={ <ReturnAskTheExpertsLogo className={"h-10 mb-1"} id={data.eventSession.id} title={data.eventSession.eventSessionName}/>}>
     <h2 className="text-lg font-bold text-gray-800  mb-3">
          {sessionName}
        </h2> 
     <p className="text-gray-500 text-sm">{data.eventSession.eventSessionDesc}</p> </Card>
      )}

    return (   
      <div className={`py-8 border-b border-gray-200 pr-6 ${index % 2 ? 'md:border-l md:pl-6' : 'md:pr-6' }`}> 
          <div className=" mb-2"> 
          <ReturnAskTheExpertsLogo className={"h-10 mb-1"} id={data.eventSession.id} title={data.eventSession.eventSessionName}/>
          </div> 
          <h3 className="font-semibold text-gray-900 text-lg pb-1">{sessionName}</h3>
          <p className="text-gray-500 text-sm">{data.eventSession.eventSessionDesc}</p>
      </div>
   
    )  
  } 


  return (
    <div className="grid grid-cols-1 md:grid-cols-2"> 
        {allEvents && allEvents.map((item, index) => (<Item key={index} index={index} data={item} />))} 
    </div>
  );
}

export default AskTheExperts;
