import { AcademicCapIcon, ChatAlt2Icon, CalendarIcon } from '@heroicons/react/solid'


export const eventDetails = {
  name: "CBEE December 2021",
  eyebrow: "December 8-9, 2021",
  description:
    "Whether you're early in your career or retirement's around the corner, our CalPERS Benefit Educational Event (CBEE) will provide you the opportunity to learn about and prepare for retirement.",
};

export const eventHighlights = [
  {
    id: "eh1",
    text: "Real-time virtual classes", 
    description:
      "We offer informative classes customized for you and your career stage: Early through mid-career or nearing retirement.",
    url: "/schedule",
    linkText: "View Schedule",
    icon: AcademicCapIcon,
    color: "#E95800",
    badge:true,
  },
  {
    id: "eh2",

    text: "Connect personally with experts", 
    description:
      "Drop in for a one-on-one web chat with representatives from CalPERS programs, health benefit plans, deferred compensation and more.",
    url: "/schedule",
    linkText: "Ask the Experts",
    icon: ChatAlt2Icon,
    color: "#E95800",
  },
  {
    id: "eh3",

    text: "Two interactive days ", 
    description:
      "The same classes and representatives are available both days, giving you more flexibility to attend when it’s convenient for you.",
    url: "/schedule",
    linkText: "View Schedule",
    icon: CalendarIcon,
    color: "#E95800",
  },
]
 