import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../components/AuthProvider";
import Logo from "./Logo";


function PostEventSplashPage() {


  return (
    <>
      <div className="bg-gray-50 min-h-screen flex flex-col items-center">
        <div className="p-8 md:p-20 max-w-4xl mx-auto">
          <div className="w-full sm:w-2/3 ">
            <Logo includeTitle={true} fill="#0071AD" />
          </div>
          <div className="shadow-md p-6 md:px-12 md:py-10 rounded-lg bg-white border-t-4 border-brand-500 mt-8 text-gray-600 text-lg">

          <p className="mb-8">Gain a better understanding of your member benefits and ask our experts questions at a virtual CalPERS Benefits Education Event (CBEE). The two-day event includes live, instructor-led <a href="https://www.calpers.ca.gov/page/education-center/member-education/benefits-education-events/event-classes" target={"_blank"} title="Opens in a new window" className="underline font-semibold text-brand-500">classes</a> and Ask the Experts rooms hosted by our CalPERS programs and partnering organizations.</p>

          <p>We’ll be hosting more events in 2023. Subscribe to our <a href="https://www.calpers.ca.gov/page/email-subscriptions" target={"_blank"} title="Opens in a new window" className="underline font-semibold text-brand-500">Member Education Bulletin</a> to be notified of future CBEEs and other educational opportunities</p>
          </div>
        </div>
      </div>

    </>
  )
}


export default PostEventSplashPage

