import React, {useState, useContext} from 'react';
import { Link, navigate } from "gatsby"; 
import { AuthContext } from "../components/AuthProvider";
import ErrorComponent from './Form/ErrorComponent';  

function HeroForm({hasEventEnded}) {
    const { user, login, logout, isUserLoggedIn, loginErrors, requestPinCode, spinner, loggingInStatus, loginState, changeLoginState, } = useContext(AuthContext);
 
    const [emailSubmitted, setEmailSubmitted] = useState(false)
    const [pinSubmitted, setPinSubmitted] = useState(false)

    const [username, setUsername] = useState('')
    const [pin, setPin] = useState('')


    if (isUserLoggedIn()) {
      navigate("/account/dashboard");
      return null;
    }

    
    function handleRequestPinCode(e) {

      e.preventDefault(); 
      setEmailSubmitted(true)
   
      
      if(username && username.length > 0) {
        requestPinCode(username); 
      }
    }

    const UserLoggedInMessage = () => (
      <a href="/account/dashboard">
        <p className="text-gray-900 font-bold text-center">Logged in as</p>
<p className="text-gray-600 text-center">{user && user.email}</p>
      
      <div className="my-2 inline-flex justify-center items-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white  font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-brand-500">
      <span className="inline-block h-8 w-8 rounded-full overflow-hidden bg-gray-100">
<svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
<path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
</svg>
</span> <span className=" ml-2 block capitalize font-semibold">{`${user && user.firstName} ${user && user.lastName}`}</span></div>

</a>
    )
    return (
 
  <div className="bg-white rounded-xl  border-brand-500 md-mb-none shadow-xl relative overflow-hidden">
   {/* {hasEventEnded ? null :  <div className="px-12 py-8 text-center">
      <span className="font-semibold text-lg">Register Now</span>
      <p className="text-gray-700">
      Reserve your spot to attend our free, virtual education event.
      </p>
      <Button
        onClick={() => { navigate('/registration/') }}
        type="button"
        className="block   w-full   text-base mt-4 font-semibold "
      >
        Reserve My Spot
      </Button>
    </div>} */}

    {isUserLoggedIn() ? <div className={`bg-gray-50 p-8 progress-border relative`}><UserLoggedInMessage/></div> :  <><div className={`${hasEventEnded ? 'bg-white py-12' : 'bg-white'} p-10 progress-border relative ${loginState == 2 ? 'half' : ''}`}>
  
  {loginState == 1 ? <form onSubmit={(e) => {handleRequestPinCode(e)}} >
    <span className={`font-semibold block ${hasEventEnded ? 'text-left  text-xl mb-2 font-bold' : ' text-left text-xl mb-2 font-black'} `}>Log In</span>
    <p className={`text-gray-700 text-left mb-1`}>
    Enter your email to log in below. A pin code will be sent to your email.
    </p>

    


    <div className={`my-4 ${emailSubmitted && username.length < 1 ||  loginErrors && loginErrors.length > 0 ? ' input-error': ''}`}>
      <label htmlFor="email" className="block font-medium text-gray-700 sr-only">
        Email
      </label>
      <div className="mt-1">
      <input
        type="text"
        name="email"
       required={true}
        id="email"
        onChange={(e)=>setUsername(e.target.value)}
        className="py-3 shadow-sm focus:ring-brand-500 focus:border-brand-500 block w-full border-gray-200 rounded-md"
        placeholder="Email"
        value={username}
      />
       </div>
    </div> 
    {loginErrors && loginErrors.length > 0 ? loginErrors.map((error,e)=><div className="mb-4"><ErrorComponent  key={e} message={error.message}/></div>) : null}
    {emailSubmitted && username.length < 1 ? <div className="mb-4"><ErrorComponent message={'Please enter a valid email'}/></div> : null}
 
    <button
      type="submit"
    
      className="font-display w-full justify-center flex items-center px-4 py-3 border border-transparent text-base mt-2 font-semibold rounded-md shadow-sm text-white bg-brand-500 hover:bg-brand-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
    >
      {spinner ? <><svg className="animate-spin mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>Pin Code Requested</> : 'Continue'}
    </button>
   {!hasEventEnded && <Link to="/registration" title="Register for CBEE" className='text-brand-600 font-semibold block text-center py-1 mt-4'>Not Registered?</Link> } 
 </form> : null}

  
   {loginState == 2 ?
<form onSubmit={(e) => {e.preventDefault(); login(username, pin); setPinSubmitted(true)}}> 
       <span className={`font-semibold block text-left  text-xl mb-2  font-black `}>{username}</span>
       <p className={`text-gray-700  text-left mb-1`}>
    We just sent you a temporary pin code. Please check your inbox.
    </p>
    <div className={`my-4 ${loginErrors && loginErrors.length > 0 ? ' input-error' : ''}`}>
      <label htmlFor="email" className="sr-only">
        Pin Code
      </label>
      <input
        type="text" 
        id="pin" 
        onChange={(e)=>setPin(e.target.value)}
        className="shadow-sm focus:ring-brand-500 focus:border-brand-500 block w-full sm:text-sm border-gray-100 rounded-md py-3"
        placeholder="Pin Code"
        value={pin}
      />
    </div>
 
    {loginErrors && loginErrors.length > 0 ? loginErrors.map((error,e)=><ErrorComponent  key={e} message={error.message}/>) : null}
 
    <button  type="submit" value="Submit"
      className="font-display flex  justify-center w-full items-center px-4 py-3 border border-transparent text-base mt-2 font-semibold rounded-md shadow-sm text-white bg-brand-500 hover:bg-brand-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
    >
     {spinner ? <svg className="animate-spin mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg> : ''} {loggingInStatus}
    </button>
    <button className="text-center text-gray-600 text-sm text-brand-600 items-center  pt-3 flex mx-auto " onClick={()=>changeLoginState(1)}>Log in with a different email.</button>

    </form>: null}  </div></>}

   
  </div>
 
    );
}

export default HeroForm;