import React, { useEffect, useState } from "react"; 
import { eventHighlights } from "../data/EventDetails";
import Layout from "../components/layout"; 
import HeroForm from "../components/HeroForm";
import Logo from "../components/Logo";
import Navbar from "../components/Navbar";
import ScheduleRegisteredUser from "../components/ScheduleRegisteredUser";
import AskTheExperts from "../components/AskTheExperts";
import { Link } from "gatsby";
import SEO from '../components/SEO'
import {Spinner} from '../components/Atoms/Spinner'
import config from "../config"
import { hasEventEnded, returnDateToUseForCECRequests } from '../helpers/dates'
import { useLocalStorage } from "../hooks/useLocalStorage";
import PostEventSplashPage from '../components/PostEventSplashPage'
import { useLocalStorage as useMantineLocalStorage} from '@mantine/hooks';
const dayjs = require("dayjs");
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone') // dependent on utc plugin

dayjs.extend(utc)
dayjs.extend(timezone)

const pacificTimeZone = 'America/Los_Angeles'

const FORMOFFSETPX = 350;

function IndexPage(props) { 
const {location} = props
  const [eventStartDate, setEventStartDate] = useMantineLocalStorage({ key: 'eventStartDate' });
  const [, setEventEndDate] = useMantineLocalStorage({ key: 'eventEndDate' });

  const [futureVirtualCBE, setFutureVirtualCBE] = useState(null);
  const [askTheExperts, setAskTheExperts] = useState(null);
  const [eventEnded, setEventEnded] = useLocalStorage('hasEventEnded', '')
  const [showSplashPage, setShowSplashPage] = useMantineLocalStorage({ key: 'showSplash' });
  const [showError, setShowError] = useState(false);

   // Client-side runtime data fetch
  useEffect(() => { 
    
    fetch(config.API.CEC.URL + '/cec-classmgmt/event/future-virtual-cbe?startDate=' + returnDateToUseForCECRequests())
      .then(response =>  {
        let showSplash = false
        let showSplashPreRegNotPassed = false
        let showSplashEventEndedPlusSevenDays = false

        if(response.status == 400) {
          showSplash = true
        }  
        if(response.status== 500) {
          setShowError(true)
        }
        response.json().then(resultData => { 
          
            if(resultData && resultData.preRegStartDttm && dayjs(resultData.preRegStartDttm).diff(dayjs(), 'seconds') > 0) {
              showSplashPreRegNotPassed = true
            }
            if(resultData && resultData.endDate && dayjs(resultData.endDate).add(7, 'day').diff(dayjs(), 'seconds') < 0) {
              showSplashEventEndedPlusSevenDays = true
            }


            // console.log("error Code",showSplash)
            // console.log("showSplashPreRegNotPassed",showSplashPreRegNotPassed, resultData.preRegStartDttm)
            // console.log("showSplashEventEndedPlusSevenDays",showSplashEventEndedPlusSevenDays)
          if(showSplash == false && showSplashPreRegNotPassed == false && showSplashEventEndedPlusSevenDays == false) {
           setShowSplashPage(false)
          } else {
            setShowSplashPage(true)
          }

        setFutureVirtualCBE(resultData)
        setAskTheExperts(resultData && resultData.eventDayDetails)

        const eventDayDetails = resultData && resultData.eventDayDetails.sort((a,b)=> a.eventDayDtlEndDttm > b.eventDayDtlEndDttm ? 1 : -1)

        setEventStartDate(eventDayDetails && eventDayDetails[0] && eventDayDetails[0].eventDayDtlEndDttm)
        setEventEndDate(eventDayDetails && eventDayDetails[1] && eventDayDetails[1].eventDayDtlEndDttm)


        setEventEnded(hasEventEnded(eventDayDetails && eventDayDetails[1] && eventDayDetails[1].eventDayDtlEndDttm))

        // after the elements render on the page, scroll to the element id that matches URL hash
        const element = document.getElementById(location.hash.replace("#", ""));

        window.scrollTo({
          behavior: element ? "smooth" : "auto",
          top: element ? element.offsetTop - 20 : 0
        })  

        


      })}) // parse JSON from request
      
    }, [])
 
 
    if(showSplashPage) {
      return     <>     
  <SEO title={"CalPERS Benefits Education Event | CBEE"}/>
        <PostEventSplashPage/>
    </>
    }
 
    if(showError) {
      return     <>     
  <SEO title={"CalPERS Benefits Education Event | CBEE"}/>
  <div className="bg-gray-50 min-h-screen flex flex-col items-center">
        <div className="p-8 md:p-20 max-w-4xl mx-auto">
         
          <div className="shadow-md p-6 md:px-12 md:py-10 rounded-lg bg-white border-t-4 border-red-500 mt-8 text-gray-600 text-lg">

        <p className="font-semibold text-red-600 text-xl mb-2">Something went wrong</p>
          <p>We're currently experiencing technical difficulties. Our dedicated technical team is actively addressing the issue to restore full functionality promptly. We apologize and appreciate your patience and understanding during this time.  </p>
          </div>
        </div>
      </div>

 
    </>
    }

  return (
    <Layout location={props.location} showHeader={true}>
      <SEO title={"CalPERS Benefits Education Event | CBEE"}/>
      <div className="max-w-7xl mx-auto px-4 md:px-6" id="top">
      <div className="py-4 flex justify-between items-center">
      <div className="w-80">
                <Link
                  to={"/"}
                  className="block focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
                >
                  <Logo fill="#00659e" includeTitle={true} />
                </Link>
              </div>
       
      </div>
      </div>
      <Navbar location={props.location}  hasEventEnded={eventEnded}  />

      {futureVirtualCBE ? <div>
        <div className="overflow-hidden">
        <div className="h-auto bg-cover relative bg-brand-900">
 
          <img
            src="/images/pers_pattern_1.jpg"
            className="hero-image absolute top-0 h-full"
            style={{ opacity: "1", transform: "none" }}
            alt="CalPERS Building"
          />

          <div className="relative">
            <div
              className={`max-w-7xl py-12 carousel-content-container flex flex-wrap justify-between relative mx-auto px-4 md:px-6`}
            >
              <div className="w-full md:w-1/2 lg:w-2/3 pt-0 py-8 md:py-12 md:pr-8 lg:pr-32 relative ">
                <div className="max-w-2xl">
                <h1 className="text-shadow-lg text-center md:text-left text-white text-shadow  relative mb-2 text-2xl md:text-3xl font-bold leading-10">
                  {dayjs(futureVirtualCBE && futureVirtualCBE.startDate).format('MMMM D')} - {dayjs(futureVirtualCBE && futureVirtualCBE.endDate).format('D, YYYY')} {config.APP.APP_NAME}
                  </h1>
                  <h2 className="text-center md:text-left block font-semibold my-2 text-lg text-white tracking-wide service-heading-tag">
                    {/* {futureVirtualCBE &&
                    futureVirtualCBE.startDate &&
                    futureVirtualCBE.startDate &&
                    futureVirtualCBE.endDate
                      ? returnReadableDateRange(
                          futureVirtualCBE.startDate,
                          futureVirtualCBE.endDate
                        )
                      : ""} */}
                    {dayjs(futureVirtualCBE && futureVirtualCBE.eventDayDetails && futureVirtualCBE.eventDayDetails[0] && futureVirtualCBE.eventDayDetails[0].eventDayDtlStartDttm).tz(pacificTimeZone).format('h:mm a').replace('am', 'a.m.').replace('pm', 'p.m.')} - {dayjs(futureVirtualCBE && futureVirtualCBE.eventDayDetails && futureVirtualCBE.eventDayDetails[0] && futureVirtualCBE.eventDayDetails[0].eventDayDtlEndDttm).tz(pacificTimeZone).format('h:mm a').replace('am', 'a.m.').replace('pm', 'p.m.')} (PT)

                  </h2>
                  
                  {eventEnded ? 
                  
                  <p className="text-shadow-md text-center md:text-left text-white mt-1 text-lg leading-7 md:leading-8 ">
            <p className="text-shadow-md text-center md:text-left text-white mt-1 text-lg leading-7 md:leading-8 ">
            Our CBEE has concluded. If you registered for this event, you can log in to review resources from classes and Ask the Experts rooms through {dayjs(eventStartDate).add(7, 'day').format('MMMM D')}.</p>
                </p>
                
                :  <p className="text-shadow-md text-center md:text-left text-white mt-1 text-lg leading-7 md:leading-8 ">
                    {futureVirtualCBE && futureVirtualCBE.eventDetails}
                  </p> }
                  <span className="hidden">{futureVirtualCBE && futureVirtualCBE.eventDayDetails[1] && futureVirtualCBE && futureVirtualCBE.eventDayDetails[1].eventDayDtlEndDttm}</span>
                 
                 
                </div>
              </div>
              <div className="w-full md:w-1/2 lg:w-1/3 form-offset">
                <HeroForm hasEventEnded={eventEnded} />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ height: FORMOFFSETPX + "px" }}
          className="height-none-md"
        />
      </div>

      <div className="bg-white">
        <div className="max-w-7xl mx-auto px-4 md:px-6">
          <ul className="flex flex-wrap">
            {eventHighlights.map((hightlight, i) => (
              <li
                key={hightlight.id}
                className={`w-full md:w-1/3 text-center p-10 py-12 ${
                  i > 0 ? " md:border-l" : ""
                }`}
              >
                <div className="bg-white border-2 border-brand-500 flex h-16 items-center justify-center mb-3 mx-auto rounded-full w-16">
                  <hightlight.icon
                    className="h-6 w-6 mx-auto"
                    style={{ color: "#006BA6" }}
                    aria-hidden="true"
                  />
                </div>
                <span className="text-base font-semibold mb-2 block">
                  {hightlight.text}
                </span>

                <p className="text-gray-500">{hightlight.description}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="bg-gray-50" id="schedule">
        <div className="max-w-7xl mx-auto px-4 md:px-6 py-8 md:py-16">
          <h2 className=" text-gray-900   relative mb-2 text-2xl md:text-3xl font-bold leading-10">
            Classes
          </h2>


          <ScheduleRegisteredUser autoSelectUpcomingClassDays  isPublicView={true} enrolledClasses={[]}  scheduleData={futureVirtualCBE && futureVirtualCBE.eventDayDetails}
          />

{/* 
          <Schedule
            scheduleData={futureVirtualCBE && futureVirtualCBE.eventDayDetails}
          /> */}
        </div>
      </div>
      <div
        className="bg-white"
        id="ask-the-experts"
      >
        <div className="max-w-7xl mx-auto px-4 md:px-6 py-8 md:py-16">
          <h2 className="  text-gray-900   relative mb-2 text-2xl md:text-3xl font-bold leading-10">
            Ask the Experts
          </h2>
          <p className="text-gray-700 max-w-4xl pb-4 mb-4">
            Experts will be on hand to answer your questions and help you learn
            more about these important programs available to you. Make the most
            of your CBEE experience by dropping in for a one-on-one virtual chat
            or browsing their resources offered during the event.
          </p>
          <AskTheExperts data={askTheExperts} />
        </div>
      </div>
      </div> :  <span className="text-center text-gray-600  py-40 block"><Spinner className="mx-auto" size="6"/></span>}
     
     
    </Layout>
  );
}

export default IndexPage;
