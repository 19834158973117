import React from 'react'

export default function Card({ title, children, className, ...props }) {
  return (
    <div className={`bg-white shadow rounded-md p-6 ${className}`}  {...props}>
      <h2 className="text-lg font-bold text-gray-800 border-b-2 border-gray-200 pb-3 mb-3">
        {title}
      </h2>
      <div>
        {children}
      </div>
    </div>
  )
}